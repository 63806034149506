import defu from 'defu'
import { boolean, fallback, type InferOutput, object, optional, parse } from 'valibot'

const metaSchema = object({
  // Whether the footer should be wide or not which is suitable for e.g. nettbutikk routes)
  wide: fallback(optional(boolean()), false),
})

export type FooterMeta = InferOutput<typeof metaSchema>

/**
 * Merge metadata from route meta and props, with the route-specific metadata taking precedence.
 */
export function useFooterMeta(props: FooterMeta) {
  const route = useRoute()

  return computed(() => parse(metaSchema, defu<FooterMeta, [FooterMeta]>(route.meta.footer ?? {}, props ?? {})))
}
